import './congratulations.css'

function Congratulations(){
    return(
        <div>
            <div class="congrats">
	        <h1 className='congratsline'>Congratulations  &#127881;</h1>
            </div>
        </div>
    )
}

export default Congratulations