import './membershipform.css'

function Membershipform(){
    return(
        <div className="membershipform">
            <h2>Membership Available</h2>
            <h5>Want to become a member of ngo?</h5>
            <button><a href='https://docs.google.com/forms/d/1Ys6vN4vjo1RKtmaFE8VIK2Nbu5Ipib7E2KVvgncIMC4/edit?pli=1'>Click here</a></button>
        </div>
    )
}

export default Membershipform