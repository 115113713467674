import './ticker.css';

function Ticker() {
  return (
        
        <div class="news-container">
            <div class="title">
                NOTE:
            </div>
    
            <ul>
                <li>
                Celebrating Annual program for our underprivileged children.  Click to know more.
                </li>
            </ul>
        </div>
  );
}

export default Ticker;
