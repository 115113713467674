export const SliderData = [
    {
      image:
        require("../img/Picture18.png")
    },
    {
      image:
        require("../img/Picture19.png")
    },
    {
      image:
        require("../img/Picture20.png")
    }
];
   