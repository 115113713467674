import './whatsapp.css'

const Whatsapp = ()=>{
    return(
        <a  class="whats-app" href="https://wa.me/message/4JPEFQXFXSQQG1" target='_blank'>
            <i class="fa fa-whatsapp my-float"></i>
        </a>
    )
}

export default Whatsapp;